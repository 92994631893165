import {
  getCompanySettings,
  saveCompany, saveCompanySettings
} from "../../../services/CompanyService";
import {
  Box,
  Button,
  Checkbox,
  Chip,
  Divider,
  FormControl,
  FormControlLabel, FormGroup,
  FormLabel,
  MenuItem,
  Paper,
  Select,
  TextField,
  Typography
} from "@mui/material";
import {useMessageContext} from "../../../contexts/MessageContext";
import {useEffect, useState} from "react";
import {Autocomplete} from "@mui/lab";
import {CompanyFeatureType} from "../../../enums/CompanyFeatureType";

const CompanySettingsTab = ({company, setLoader, setRefresh}) => {
  const {notificationHandler} = useMessageContext();

  const [reload, setReload] = useState(false);

  const [companySettings, setCompanySettings] = useState();

  useEffect(() => {
    setLoader(true);
    getCompanySettings(company.id)
        .then(res => setCompanySettings(res.data))
        .finally(() => setLoader(false))
  }, [reload]);

  const updateBrandArchetype = (brandArchetype) => {
    setCompanySettings(prv => {
      return {...prv, brandArchetype}
    })
  }

  const updateAgentRole = (agentRole) => {
    setCompanySettings(prv => {
      return {...prv, agentRole}
    })
  }

  const updateCorporateFonts = (fonts) => {
    setCompanySettings(prv => {
      return {...prv, fonts}
    })
  }

  const updateCorporateColors = (colors) => {
    setCompanySettings(prv => {
      return {...prv, colors}
    })
  }

  const updateIndustryPublication = (industryPublication) => {
    setCompanySettings(prv => {
      return {...prv, industryPublication}
    })
  }

  const updateNewsQuery = (newsQuery) => {
    setCompanySettings(prv => {
      return {...prv, newsQuery}
    })
  }

  const updateX = (x) => {
    setCompanySettings(prv => {
      let bannerDefaults = {...prv.bannerDefaults};
      bannerDefaults["x"] = x;
      return {...prv, bannerDefaults}
    })
  }

  const updateY = (y) => {
    setCompanySettings(prv => {
      let bannerDefaults = {...prv.bannerDefaults};
      bannerDefaults["y"] = y;
      return {...prv, bannerDefaults}
    })
  }

  const updateMaxWidth = (maxWidth) => {
    setCompanySettings(prv => {
      let bannerDefaults = {...prv.bannerDefaults};
      bannerDefaults["maxWidth"] = maxWidth;
      return {...prv, bannerDefaults}
    })
  }

  const updateMaxHeight = (maxHeight) => {
    setCompanySettings(prv => {
      let bannerDefaults = {...prv.bannerDefaults};
      bannerDefaults["maxHeight"] = maxHeight;
      return {...prv, bannerDefaults}
    })
  }

  const updateFontSize = (fontSize) => {
    setCompanySettings(prv => {
      let bannerDefaults = {...prv.bannerDefaults};
      bannerDefaults["fontSize"] = fontSize;
      return {...prv, bannerDefaults}
    })
  }

  const updateColor = (color) => {
    setCompanySettings(prv => {
      let bannerDefaults = {...prv.bannerDefaults};
      bannerDefaults["color"] = color;
      return {...prv, bannerDefaults}
    })
  }

  /// Article
  const updateArticleX = (x) => {
    setCompanySettings(prv => {
      let articleDefaults = {...prv.articleDefaults};
      articleDefaults["x"] = x;
      return {...prv, articleDefaults}
    })
  }

  const updateArticleY = (y) => {
    setCompanySettings(prv => {
      let articleDefaults = {...prv.articleDefaults};
      articleDefaults["y"] = y;
      return {...prv, articleDefaults}
    })
  }

  const updateArticleMaxWidth = (maxWidth) => {
    setCompanySettings(prv => {
      let articleDefaults = {...prv.articleDefaults};
      articleDefaults["maxWidth"] = maxWidth;
      return {...prv, articleDefaults}
    })
  }

  const updateArticleMaxHeight = (maxHeight) => {
    setCompanySettings(prv => {
      let articleDefaults = {...prv.articleDefaults};
      articleDefaults["maxHeight"] = maxHeight;
      return {...prv, articleDefaults}
    })
  }

  const updateArticleFontSize = (fontSize) => {
    setCompanySettings(prv => {
      let articleDefaults = {...prv.articleDefaults};
      articleDefaults["fontSize"] = fontSize;
      return {...prv, articleDefaults}
    })
  }

  const updateArticleFont = (font) => {
    setCompanySettings(prv => {
      let articleDefaults = {...prv.articleDefaults};
      articleDefaults["font"] = font;
      return {...prv, articleDefaults}})
  }

  const updateBannerFont = (font) => {
    setCompanySettings(prv => {
      let bannerDefaults = {...prv.bannerDefaults};
      bannerDefaults["font"] = font;
      return {...prv, bannerDefaults}})
  }

  const updateArticleColor = (color) => {
    setCompanySettings(prv => {
      let articleDefaults = {...prv.articleDefaults};
      articleDefaults["color"] = color;
      return {...prv, articleDefaults}
    })
  }

  const updateFeature = (feature) => {
    setCompanySettings(prv => {
      let enabledFeatures = [...prv.enabledFeatures];
      if (enabledFeatures.includes(feature)) {
        enabledFeatures = enabledFeatures.filter(f => f !== feature);
      } else {
        enabledFeatures.push(feature);
      }

      console.log(enabledFeatures);
      return {...prv, enabledFeatures}
    });
  }

  const fonts = ["Inter-Bold",
    "Inter-Regular",
    "Helvetica-Bold",
    "Helvetica-Regular",
    "Lato-Bold",
    "Lato-Regular",
    "Montserrat-Bold",
    "Montserrat-Regular",
    "OpenSans-Bold",
    "OpenSans-Regular",
    "Poppins-Bold",
    "Poppins-Regular",
    "Roboto-Bold",
    "Roboto-Regular",
    "SourceSerif4-Bold",
    "SourceSerif4-Regular",
    "Ubuntu-Bold",
    "Ubuntu-Regular",
    "Manrope-Bold",
    "Manrope-Regular",
    "NotoSans-Bold",
    "NotoSans-Regular",]

  const brandArchetypes = [
    {
      value: "THE_CAREGIVER",
      name: "The Caregiver",
      description: "Traits: Compassionate, nurturing, selfless, supportive. Example: Allstate – emphasizes care and support for families."
    },
    {
      value: "THE_RULER",
      name: "The Ruler",
      description: "Traits: Authoritative, organized, responsible, decisive. Example: Rolex, microsoft – positioned as a symbol of luxury, precision, and status."
    },
    {
      value: "THE_CREATOR",
      name: "The Creator",
      description: "Traits: Innovative, imaginative, artistic, visionary. Example: apple – known for fostering creativity and imagination in technology."
    },
    {
      value: "THE_INNOCENT",
      name: "The Innocent",
      description: "Traits: Pure, optimistic, simple, honest. Example: Coca-Cola – often associated with happiness, joy, and simplicity."
    },
    {
      value: "THE_SAGE",
      name: "The Sage",
      description: "Traits: Wise, knowledgeable, analytical, insightful. Example: Google – positioned as a source of knowledge and information."
    },
    {
      value: "THE_EXPLORER",
      name: "The Explorer",
      description: "Traits: Adventurous, independent, ambitious, freedom-seeking. Example: The Jeep – appeals to those who seek adventure and exploration."
    },
    {
      value: "THE_REBEL",
      name: "The Rebel",
      description: "Traits: Outlaw, revolutionary, nonconformist, daring. Example: Harley-Davidson – associated with freedom and rebellion."
    },
    {
      value: "THE_MAGICIAN",
      name: "The Magician",
      description: "Traits: Visionary, imaginative, transformative, charismatic. Example: Disney – known for its innovative and transformative products."
    },
    {
      value: "THE_HERO",
      name: "The Hero",
      description: "Traits: Courageous, strong, determined, triumphant. Example: Nike – known for its empowering and motivational messaging."
    },
    {
      value: "THE_LOVER",
      name: "The Lover",
      description: "Traits: Passionate, sensual, romantic, committed. Example: Victoria's Secret – known for its emphasis on sensuality and romance."
    },
    {
      value: "THE_JESTER",
      name: "The Jester",
      description: "Traits: Playful, humorous, irreverent, carefree. Example: M&M's – uses humor and playfulness in its advertising."
    },
    {
      value: "THE_REGULAR_GUY",
      name: "The Regular Guy/Girl (Everyman)",
      description: "Traits: Down-to-earth, relatable, genuine, friendly. Example: IKEA – positions itself as an accessible and affordable brand for everyone."
    }
  ];


  return (
      <Paper component={"form"}
                onSubmit={(event => {
                  event.preventDefault();
                  setLoader(true);
                  // const formData = new FormData(event.currentTarget);
                  // const formJson = Object.fromEntries(formData.entries());
                  let body = {...companySettings}
                  body["companyId"] = company.id;
                  saveCompanySettings(body)
                      .then(() => setRefresh(prv => !prv))
                      .then(() => notificationHandler.success('Company Settings Saved'))
                      .finally(() => setLoader(false))
                })}
                sx={{margin: "48px", padding: "24px"}} elevation={2} >
        {companySettings && <>
     <FormControl fullWidth sx={{mt: "16px"}}>
          <FormLabel>Brand Archetype</FormLabel>
          <Select
              fullWidth
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              label="Brand Archetype"
              variant={"standard"}
              value={companySettings.brandArchetype}
              onChange={(e) => updateBrandArchetype(e.target.value)}
          >

            {brandArchetypes.map((archetype, i) => {
              return <MenuItem key={i} value={archetype.value}>
                <Box>
                <Typography sx={{fontSize: "14px",fontWeight: 600}}>{archetype.name}</Typography>
                <Typography sx={{fontSize: "12px"}}>{archetype.description}</Typography>
                </Box></MenuItem>
            })}
          </Select>
        </FormControl>
        <TextField
            sx={{mt: "16px"}}
            fullWidth
            multiline
            minRows={3}
            margin="dense"
            id="agentRole"
            name="agentRole"
            label="Agent Role"
            type="text"
            onChange={e => updateAgentRole(e.target.value)}
            value={companySettings?.agentRole}
        />
        <Autocomplete
            multiple
            fullWidth
            id="tags-filled"
            sx={{mt: "16px","& .MuiAutocomplete-inputRoot" : {minHeight: "100px"}}}
            options={[].map((option) => option.title)}
            freeSolo
            value={companySettings?.fonts}
            onChange={(event, newValue) => {
              updateCorporateFonts(newValue);
            }}
            renderTags={(value, getTagProps) =>
                value.map((option, index) => (
                    <Chip variant="filled" label={option} {...getTagProps({ index })} />
                ))
            }
            renderInput={(params) => (
                <TextField
                    fullWidth
                    {...params}
                    label="Corporate Fonts"
                />
            )}
        />
        <Autocomplete
            multiple
            fullWidth
            id="tags-filled"
            sx={{mt: "16px","& .MuiAutocomplete-inputRoot" : {minHeight: "100px"}}}
            options={[].map((option) => option.title)}
            freeSolo
            value={companySettings?.colors}
            onChange={(event, newValue) => {
              updateCorporateColors(newValue);
            }}
            renderTags={(value, getTagProps) =>
                value.map((option, index) => (
                    <Chip variant="filled" label={option} {...getTagProps({ index })} />
                ))
            }
            renderInput={(params) => (
                <TextField
                    fullWidth
                    {...params}
                    label="Corporate Colors"
                />
            )}
        />
        <Autocomplete
            multiple
            fullWidth
            id="tags-filled"
            sx={{mt: "16px","& .MuiAutocomplete-inputRoot" : {minHeight: "100px"}}}
            options={[].map((option) => option.title)}
            freeSolo
            value={companySettings?.industryPublication}
            onChange={(event, newValue) => {
              updateIndustryPublication(newValue);
            }}
            renderTags={(value, getTagProps) =>
                value.map((option, index) => (
                    <Chip variant="filled" label={option} {...getTagProps({ index })} />
                ))
            }
            renderInput={(params) => (
                <TextField
                    fullWidth
                    {...params}
                    label="Industry Publication"
                />
            )}
        />
        <TextField
            sx={{mt: "16px"}}
            fullWidth
            multiline
            minRows={3}
            margin="dense"
            id="newsQuery"
            name="newsQuery"
            label="News Query"
            type="text"
            onChange={e => updateNewsQuery(e.target.value)}
            value={companySettings?.newsQuery}
        />
        <Divider sx={{mt: "16px"}}>Brand Banners</Divider>
        <Box sx={{display: "flex", justifyContent: "space-between", alignItems: "start"}}>
          <Box  sx={{width: "47%", display: "flex", justifyContent: "space-between", alignItems: "center"}}>
            <TextField
                sx={{width: "43%"}}
                margin="dense"
                id="fontSize"
                name="fontSize"
                label="Font Size"
                type="number"
                onChange={(e) => updateFontSize(e.target.value)}
                value={companySettings?.bannerDefaults?.fontSize}
            />
            <Select
                sx={{width: "43%"}}
                labelId="demo-simple-select-label"
                id="font"
                value={companySettings?.bannerDefaults?.font}
                onChange={(e) => updateBannerFont(e.target.value)}
            >

              {fonts.map((font, i) => {
                return <MenuItem value={font}>{font}</MenuItem>
              })}
            </Select>
          </Box>
          <TextField
              sx={{width: "47%"}}
              margin="dense"
              id="color"
              name="color"
              label="Font Color"
              type="text"
              onChange={(e) => updateColor(e.target.value)}
              value={companySettings?.bannerDefaults?.color}
          />
        </Box>
        <Box sx={{display: "flex", justifyContent: "space-between", alignItems: "start"}}>
          <TextField
              sx={{width: "20%"}}
              margin="dense"
              id="x"
              name="x"
              label="x"
              type="number"
              onChange={(e) => updateX(e.target.value)}
              value={companySettings?.bannerDefaults?.x}
          />
          <TextField
              sx={{width: "20%"}}
              margin="dense"
              id="y"
              name="y"
              label="y"
              type="number"
              onChange={(e) => updateY(e.target.value)}
              value={companySettings.bannerDefaults?.y}
          />
          <TextField
              sx={{width: "20%"}}
              margin="dense"
              id="maxWidth"
              name="maxWidth"
              label="Max Width"
              type="number"
              onChange={(e) => updateMaxWidth(e.target.value)}
              value={companySettings.bannerDefaults?.maxWidth}
          />
          <TextField
              sx={{width: "20%"}}
              margin="dense"
              id="maxHeight"
              name="maxHeight"
              label="Max Height"
              type="text"
              onChange={(e) => updateMaxHeight(e.target.value)}
              value={companySettings.bannerDefaults?.maxHeight}
          />
        </Box>
        <Divider sx={{mt: "16px"}}>Article Image</Divider>
        <Box sx={{display: "flex", justifyContent: "space-between", alignItems: "start"}}>
          <Box  sx={{width: "47%", display: "flex", justifyContent: "space-between", alignItems: "center"}}>
          <TextField
              sx={{width: "43%"}}
              margin="dense"
              id="fontSize"
              name="fontSize"
              label="Font Size"
              type="number"
              onChange={(e) => updateArticleFontSize(e.target.value)}
              value={companySettings?.articleDefaults?.fontSize}
          />
          <Select
              sx={{width: "43%"}}
              labelId="demo-simple-select-label"
              id="font"
              value={companySettings?.articleDefaults?.font}
              onChange={(e) => updateArticleFont(e.target.value)}
          >

            {fonts.map((font, i) => {
              return <MenuItem value={font}>{font}</MenuItem>
            })}
          </Select>
          </Box>
          <TextField
              sx={{width: "47%"}}
              margin="dense"
              id="color"
              name="color"
              label="Font Color"
              type="text"
              onChange={(e) => updateArticleColor(e.target.value)}
              value={companySettings?.articleDefaults?.color}
          />
        </Box>
        <Box sx={{display: "flex", justifyContent: "space-between", alignItems: "start"}}>
          <TextField
              sx={{width: "20%"}}
              margin="dense"
              id="x"
              name="x"
              label="x"
              type="number"
              onChange={(e) => updateArticleX(e.target.value)}
              value={companySettings?.articleDefaults?.x}
          />
          <TextField
              sx={{width: "20%"}}
              margin="dense"
              id="y"
              name="y"
              label="y"
              type="number"
              onChange={(e) => updateArticleY(e.target.value)}
              value={companySettings.articleDefaults?.y}
          />
          <TextField
              sx={{width: "20%"}}
              margin="dense"
              id="maxWidth"
              name="maxWidth"
              label="Max Width"
              type="number"
              onChange={(e) => updateArticleMaxWidth(e.target.value)}
              value={companySettings.articleDefaults?.maxWidth}
          />
          <TextField
              sx={{width: "20%"}}
              margin="dense"
              id="maxHeight"
              name="maxHeight"
              label="Max Height"
              type="text"
              onChange={(e) => updateArticleMaxHeight(e.target.value)}
              value={companySettings.articleDefaults?.maxHeight}
          />
        </Box>
          <Divider>Automatic Publish Features</Divider>
          <Box sx={{display: "flex", justifyContent: "space-between", alignItems: "start"}}>
            <FormGroup>
              {Object.keys(CompanyFeatureType).map((feature, i) => {
                return <FormControlLabel key={i} control={<Checkbox checked={companySettings.enabledFeatures?.some(key => key === feature)} onChange={() => updateFeature(feature)} />} label={CompanyFeatureType[feature]} />
              })}
            </FormGroup>
          </Box>
        <Box sx={{mt: "48px", display: "flex", alignItems: "center", justifyContent: "end"}}>
          <Button type="submit" variant={"contained"}>save</Button>
        </Box>
        </>}
  </Paper>
      );

};

export default CompanySettingsTab;
