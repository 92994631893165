import {useRef, useState} from "react";
import {useMessageContext} from "../../contexts/MessageContext";
import {
  Box, Button, Checkbox, FormControl, FormControlLabel, InputLabel, MenuItem, Select, TextField
} from "@mui/material";
import {fullManualBrandingBanner} from "../../services/BrandingBannerService";

const BrandingBannerPlayground = ({companies, setLoading}) => {

  const [data, setData] = useState({});

  const inputFileRef = useRef( null );

  const [imageTemplate, setImageTemplate] = useState({});

  const {notificationHandler} = useMessageContext();

  const [selectedFont, setSelectedFont] = useState("Inter-Bold");

  const fonts = ["Inter-Bold",
    "Inter-Regular",
    "Helvetica-Bold",
    "Helvetica-Regular",
    "Lato-Bold",
    "Lato-Regular",
    "Montserrat-Bold",
    "Montserrat-Regular",
    "OpenSans-Bold",
    "OpenSans-Regular",
    "Poppins-Bold",
    "Poppins-Regular",
    "Roboto-Bold",
    "Roboto-Regular",
    "SourceSerif4-Bold",
    "SourceSerif4-Regular",
    "Ubuntu-Bold",
    "Ubuntu-Regular",
    "Manrope-Bold",
    "Manrope-Regular",
    "NotoSans-Bold",
    "NotoSans-Regular",]

  const handleSubmit = () => {
    setLoading(true);

    let body = {
      ...data,
      base64Image: imageTemplate.imageBase64,
      companyId: data.company?.id,
      font: selectedFont
    };

    delete body.company;

    fullManualBrandingBanner(body)
        .then(() => setData({}))
        .then(() => setImageTemplate({}))
        .then(() => notificationHandler.success('Manual Branding Banner triggered'))
        .finally(() => setLoading(false));
  };

  const onFileChange = async ( e ) => {
    /*Selected files data can be collected here.*/
    let body = await Promise.all([...e.target.files].map(async file => {
      return {
        name: file.name,
        imageBase64: await convertBase64(file)
      }
    }));

    setImageTemplate(body[0]);
  }

  const convertBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const fileReader = new FileReader();
      fileReader.readAsDataURL(file)
      fileReader.onload = () => {
        resolve(fileReader.result);
      }
      fileReader.onerror = (error) => {
        reject(error);
      }
    })
  }

  return (
      <Box sx={{display: "flex", alignItems: "center", flexDirection:"column"}}>
        <TextField
            required
            fullWidth
            sx={{width: "520px"}}
            margin="dense"
            id="imageTitle"
            name="imageTitle"
            label="Image Title"
            type="String"
            variant="standard"
            value={data.imageTitle}
            onChange={e => setData(prv => {
              return {
                ...prv,
                imageTitle: e.target.value
              }})}
        />
        <FormControl sx={{width: "520px", mt: "8px"}}>
          <InputLabel id="demo-simple-select-label">Company (Optional)</InputLabel>
          <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              label="Company (Optional)"
              variant={"standard"}
              value={data.company}
              onChange={e => setData(prv => {
                return {
                  ...prv,
                  company: e.target.value
                }
              })}
          >

            <MenuItem value="">
              <em>None</em>
            </MenuItem>
            {companies.map((company, i) => {
              return <MenuItem value={company}>{company.name}</MenuItem>
            })}
          </Select>
        </FormControl>
            <Box sx={{display: "flex", alignItems: "center", justifyContent: "center"}}>
              <TextField
                  required
                  sx={{width: "250px"}}
                  margin="dense"
                  id="color"
                  name="color"
                  label="color"
                  type="text"
                  variant="standard"
                  value={data.color}
                  onChange={e => setData(prv => {
                    return {
                      ...prv,
                      color: e.target.value
                    }
                  })}
              />
              <TextField
                  required
                  sx={{ml: "24px", width: "250px"}}
                  margin="dense"
                  id="fontSize"
                  name="fontSize"
                  label="font Size"
                  type="number"
                  variant="standard"
                  value={data.fontSize}
                  onChange={e => setData(prv => {
                    return {
                      ...prv,
                      fontSize: e.target.value
                    }
                  })}
              />
            </Box>
            <Box sx={{display: "flex", alignItems: "center", justifyContent: "center"}}>
              <TextField
                  required
                  sx={{width: "250px"}}
                  margin="dense"
                  id="x"
                  name="x"
                  label="x"
                  type="number"
                  variant="standard"
                  value={data.x}
                  onChange={e => setData(prv => {
                    return {
                      ...prv,
                      x: e.target.value
                    }
                  })}
              />
              <TextField
                  required
                  sx={{ml: "24px", width: "250px"}}
                  margin="dense"
                  id="y"
                  name="y"
                  label="y"
                  type="number"
                  variant="standard"
                  value={data.y}
                  onChange={e => setData(prv => {
                    return {
                      ...prv,
                      y: e.target.value
                    }
                  })}
              />
            </Box>
            <Box sx={{display: "flex", alignItems: "center", justifyContent: "center"}}>
              <TextField
                  required
                  sx={{width: "250px"}}
                  margin="dense"
                  id="maxWidth"
                  name="maxWidth"
                  label="maxWidth"
                  type="number"
                  variant="standard"
                  value={data.maxWidth}
                  onChange={e => setData(prv => {
                    return {
                      ...prv,
                      maxWidth: e.target.value
                    }
                  })}
              />
              <TextField
                  required
                  sx={{ml: "24px", width: "250px"}}
                  margin="dense"
                  id="maxHeight"
                  name="maxHeight"
                  label="maxHeight"
                  type="number"
                  variant="standard"
                  value={data.maxHeight}
                  onChange={e => setData(prv => {
                    return {
                      ...prv,
                      maxHeight: e.target.value
                    }
                  })}
              />
            </Box>
            <Box  sx={{display: "flex", alignItems: "center", justifyContent: "center"}}>
              <Select
                  sx={{mt: "24px"}}
                  fullWidth
                  labelId="demo-simple-select-label"
                  id="font"
                  value={selectedFont}
                  onChange={(e) => setSelectedFont(e.target.value)}
              >

                {fonts.map((font, i) => {
                  return <MenuItem value={font}>{font}</MenuItem>
                })}
              </Select>
            </Box>
        <Button
            sx={{mt: "24px"}}
            variant="contained"
            component="label"
        >
          Upload Image
          <input

              type="file"
              hidden
              ref={inputFileRef}
              onChange={onFileChange}
          />
        </Button>

        {(imageTemplate.imageBase64) &&
            <Box
                component="img"
                sx={{
                  mt: "44px",
                  height: 150,
                  width: 150,
                }}
                src={imageTemplate.imageBase64}
            />}
        <Button sx={{margin: "24px"}}
                variant={"contained"}
                disabled={(data.imageTitle == null || data.imageTitle === "")}
                onClick={handleSubmit}
        >Generate</Button>
      </Box>)
};

export default BrandingBannerPlayground;
